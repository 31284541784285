const jwtServiceConfig = {
  signIn: `/login`,
  currentUser: `/current-user`,
  device_info: `/device`,
  logout: `/logout`,
  signUp: 'api/auth/sign-up',
  accessToken: `/auth/token`,
  accessTokenAD: `/auth//token/ad`,
  updateUser: 'api/auth/user/update',
};

export default jwtServiceConfig;
