import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FuseSplashScreen from '../FuseSplashScreen';
// import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from '../../store/userSlice';
import { useSearchParams } from 'react-router-dom';
import jwtService from './services/jwtService';
// import { refreshNavigationItem } from '../store/fuse/navigationSlice';
const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {

    jwtService.on('onAutoLogin', () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      // jwtService.signInWithToken
      jwtService
        .getCurrentUser()
        .then((user) => {
          // dispatch(refreshNavigationItem());
          success(user, 'Signed in with JWT');
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    jwtService.on('onLogout', () => {

      pass('Signed out');

      dispatch(logoutUser());
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.init();

    function success(user, message) {
      // dispatch(refreshNavigationItem()).then(e => {
      //   if (message) {
      //     // dispatch(showMessage({ message }));
      //   }
      console.log("user test")
      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        console.log("user success")
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      }).catch((error) => {
        console.log("user failed");
      });;
      // })

    }

    function pass(message) {
      console.log("message")
      if (message) {
        // dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
    // <>Loading</>
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
