import FuseUtils from '..//utils';
import AppContext from '../../AppContext';
import { Component } from 'react';
import { matchRoutes } from 'react-router-dom';
import withRouter from '../withRouter';
import history from '../history';

let loginRedirectUrl = null;

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
    // console.log(props.loginRedirectUrl);
    this.defaultLoginRedirectUrl = props.loginRedirectUrl || '/';
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole, authRoles, device_info } = props;
    const { pathname } = location;

    let dummy_permission = [
      // {
      //   auth: "dashboards",
      //   label: "Emergency Board",
      //   pathname: '/dashboards/project',
      // },
      // {
      //   auth: "reports.historycreation",
      //   label: "ประวัติการสร้างรายการ",
      //   pathname: '/apps/report/historycreation',
      // },
      // {
      //   auth: "apps.employee",
      //   label: "รายชื่อพนักงาน",
      //   pathname: '/apps/configuration/employees',
      // },
      // {
      //   auth: "apps.emergencyteam",
      //   label: "การตั้งค่าทีมฉุกเฉิน",
      //   pathname: '/apps/configuration/emergency_team',
      // },
      // {
      //   auth: "apps.shift",
      //   label: "การตั้งค่ากำลังพลแผนฉุกเฉิน",
      //   pathname: '/apps/configuration/shift',
      // },
      // {
      //   auth: "apps.workschedule",
      //   label: "การตั้งค่าตารางการทำงาน",
      //   pathname: '/apps/configuration/work_schedule',
      // },
      // {
      //   auth: "apps.timeschedule",
      //   label: "การตั้งค่าตารางเวลางาน",
      //   pathname: '/apps/configuration/time_schedule',
      // },
      // {
      //   auth: "apps.user",
      //   label: "การตั้งค่าผู้ใช้งาน",
      //   pathname: '/apps/configuration/users',
      // },
      // {
      //   auth: "apps.role",
      //   label: "การตั้งค่าสิทธิ์ผู้ใช้งาน",
      //   pathname: '/apps/configuration/roles',
      // },
    ];
    console.log(state)
    const matchedRoutes = matchRoutes(state.routes, pathname);
    console.log(matchedRoutes)
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    if (matched) {
      // 
      if (matched.route.issetup == device_info?.active || matched.route.issetup == -1) {
        return {
          accessGranted: FuseUtils.hasPermission(authRoles?.[dummy_permission?.find(e => matched?.pathname?.includes(e.pathname))?.auth] || matched.route.auth, userRole),
        };
      } else {
        if (userRole) {
          if (device_info?.active == 1) {
            setTimeout(() => history.push("/welcome"), 0);
          } else {
            setTimeout(() => history.push("/firstsetup"), 0);
          }
        } else {
          return {
            accessGranted: false,
          };
        }
      }
    } else {
      return {
        accessGranted: true,
      };
    }
  }

  redirectRoute() {
    // const [searchParams] = useSearchParams();
    const { location, userRole, device_info } = this.props;
    const { pathname } = location;
    const redirectUrl = loginRedirectUrl || this.defaultLoginRedirectUrl;
    // const token = searchParams.get('token');
    // console.log("device_info")
    // console.log(device_info)
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const url_token = params.get("token");
    // const url_fullscreen = params.get("fullscreen");
    // const url_action = params.get("action");
    // console.log((url_fullscreen || url_action ? `?${url_fullscreen ? "fullscreen=" + url_fullscreen : ""}&${url_action ? "action=" + url_action : ""}` : ""));

    /*
        User is guest
        Redirect to Login Page
        */
    if (!userRole || userRole.length === 0) {
      // setTimeout(() => history.push('/login' + (url?.search)), 0);
      let redirect_login = '/login'
      console.log(url);

      if (url?.search) {
        redirect_login = redirect_login + (url?.search)
      } else if (url?.hash) {
        redirect_login = redirect_login + (url?.hash)
      }
      setTimeout(() => history.push(redirect_login), 0);
      loginRedirectUrl = pathname;
    } else if (device_info?.active == 0) {
      setTimeout(() => history.push("/firstsetup"), 0);
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      setTimeout(() => history.push(redirectUrl), 0);
      loginRedirectUrl = this.defaultLoginRedirectUrl;
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}

FuseAuthorization.contextType = AppContext;

export default withRouter(FuseAuthorization);