
import FuseSuspense from '../../core/FuseSuspense';
import AppContext from '../../AppContext';
import { memo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';

function Layout1(props) {
  // const config = useSelector(selectFuseCurrentLayoutConfig);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  return (
    // <Root id="fuse-layout" config={config} className="w-full flex">
      <div>
            <FuseSuspense>{useRoutes(routes)}</FuseSuspense>

        {props.children}
      </div>
    // </Root>
  );
}

export default memo(Layout1);
