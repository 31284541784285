import { useDeepCompareEffect } from '../hooks';
import _ from '../lodash';
import AppContext from '../../AppContext';
// import {
//   generateSettings,
//   selectFuseCurrentSettings,
//   selectFuseDefaultSettings,
//   setSettings,
// } from '../../store/fuse/settingsSlice';
import { memo, useCallback, useContext, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import { alpha } from '@mui/material/styles';

const inputGlobalStyles = (
  <GlobalStyles/>
);

function FuseLayout(props) {
  const { layouts } = props;
  const Layout = useMemo(() => layouts["layout1"], [layouts, "layout1"]);

  return  (
    <>
      {inputGlobalStyles}
      <Layout {...props} />
    </>
  );
}

export default memo(FuseLayout);
